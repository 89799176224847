import React, { useState, useEffect, useRef } from "react";
import { FormCheck } from "react-bootstrap";
import "../members/membersContent1Style.scss";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAutheticated } from "../../actions/actions";
import { TimelineLite, Power3, TweenMax } from "gsap";
import { userLanguage } from "../../actions/actions";

export default function MembersContent1({ fullpageApi, animation }) {
  const [showPassword, setShowPassword] = useState(false);
  const [wrongInput, setWrongInput] = useState(false);
  const inputRef = useRef();
  const [confirmedPassword, setConfirmedPassword] = useState(false);
  const loginPassword = "sagoscloseduser";

  let tl = new TimelineLite({ delay: 0.3 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let rowSection = useRef();

  useEffect(() => {
    TweenMax.to(rowSection, 0, { css: { visibility: "visible" }, delay: 0.3 });
  }, []);

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, {
        x: 50,
        opacity: 0,
        ease: Power3.easeOut,
      })
        .from(image2, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(
          textSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  const delayTransition = () => {
    setTimeout(() => {
      setWrongInput(false);
    }, 5000);
  };

  const authenticate = () => {
    if (inputRef.current.value === loginPassword) {
      dispatch(userAutheticated(true));
      setConfirmedPassword(true);
    } else {
      setWrongInput(true);
      delayTransition();
    }
  };

  const EnterKey = (key, cb) => {
    const callbackRef = useRef(cb);

    useEffect(() => {
      callbackRef.current = cb;
    });

    useEffect(() => {
      function handler(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener("keypress", handler);
      return () => document.removeEventListener("keypress", handler);
    }, [key]);
  };

  EnterKey("Enter", authenticate);

  useEffect(() => {
    try {
      if (fullpageApi.setAutoScrolling !== undefined) {
        fullpageApi.reBuild();
      }
    } catch (err) {
      console.log(err);
    }
  }, [fullpageApi]);

  return (
    <>
      {confirmedPassword ? <Redirect to="/membersarea"></Redirect> : null}
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === "deutsch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("deutsch"));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === "französisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("französisch"));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === "italienisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("italienisch"));
          }}
        >
          IT
        </h1>
      </div>
      <div className="navigationText" ref={(el) => (scrollSection = el)}>
        <h1>
          Information: <br></br>Bitte melden Sie sich an!
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + "images/background/arrowDown.svg"}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div className="passwordNotification">
        <p
          className={
            wrongInput ? "wrongPasswordVisible" : "wrongPasswordInvisible"
          }
        >
          Die eingegebenen Login-Daten sind nicht richtig. <br></br> Bitte
          versuchen Sie es erneut.
        </p>
      </div>
      <div
        className="row justify-content-center align-self-center membersRow1"
        ref={(el) => (rowSection = el)}
      >
        <div className="textBorderLeft" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            Willkommen in unserem <br></br>
            <span>Mitgliederbereich!</span>
          </h1>
          <p>
            In unserem Mitgliederbereich können Sie spezifische Informationen zu
            Themen wie: Treffen, Kongresse, Kursangebote, Seminare und Fragen
            zur Krankenversicherung finden!
          </p>
          <div className="buttonCenter">
            <h4>Bitte geben Sie das Passwort ein:</h4>
            <input type={showPassword ? "" : "password"} ref={inputRef}></input>
            <FormCheck
              className="showPassword"
              label="Password anzeigen"
              checked={showPassword}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              readOnly
            ></FormCheck>
            <button
              onClick={() => {
                authenticate();
                console.log(fullpageApi);
              }}
            >
              Anmelden
            </button>
          </div>
        </div>
        <div className="col-md-8">
          <img
            className="membersLogin1-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + "images/background/login1.1.svg"}
            alt="doctors"
          ></img>
          <img
            className="membersLogin1-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + "images/background/login1.2.svg"}
            alt="doctors"
          ></img>
        </div>
      </div>
    </>
  );
}
