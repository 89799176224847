import React from "react";

export const News = (props) => {
  return (
    <div>
      <p>
        <span>Daten der nächsten Ozontherapie-Seminare in der Schweiz:</span>
        <br></br>
        <br></br>
        Fortbildung in Zürich: <br /> <span>28.06.2025</span> <br />
        <br></br>
        Fortbildung in Basel: <br /> <span>22.11.2025</span> <br />
        <br></br>
      </p>
      <br></br>
      <br></br>
      <p></p>
    </div>
  );
};
