import React, { useState, useEffect, useRef } from "react";
import "../sagos/sagosContent5Style.scss";
import { Modal, Row, Col, ListGroup, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { TimelineLite, Power3 } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { userLanguage } from "../../actions/actions";

export default function SagosContent5({ fullpage, animation }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let tl = new TimelineLite({ delay: 0.8 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  const modalRight = useRef();

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let modal1Ref = useRef();

  const [modal1, setModal1] = useState(false);

  useEffect(() => {
    if (modal1) {
      modal1Ref.click();
    }
  }, [modal1]);

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, {
        x: 50,
        opacity: 0,
        ease: Power3.easeOut,
      })
        .from(image2, 1.5, { x: -50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(
          textSection,
          1.5,
          { x: -50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div>
        <Modal
          onExited={() => {
            fullpage.reBuild();
          }}
          show={show}
          onHide={() => {
            handleClose();
            setModal1(false);
            fullpage.setAutoScrolling(true);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <FontAwesomeIcon
            className="modalCloseButton"
            icon={faTimes}
            onClick={() => {
              handleClose();
              setModal1(false);
              fullpage.setAutoScrolling(true);
            }}
          ></FontAwesomeIcon>
          <Modal.Header className="modalHeader">
            <Modal.Title>
              <h2>Wichtige Ressourcen</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#bibliographie"
            >
              <Row className="modalRow">
                <Col md={4}>
                  <ListGroup className="modalLeft">
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#bibliographie"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                      ref={(el) => (modal1Ref = el)}
                    >
                      1. Bibliographie
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#links"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      2. Links
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#literatur"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      3. Literatur
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={8}>
                  <Tab.Content className="modalRight" ref={modalRight}>
                    <Tab.Pane
                      className="modalRightTab"
                      eventKey="#bibliographie"
                    >
                      <h6>Bibliographie</h6>
                      <div>
                        <p>
                          <h4>Literatur zur Ozontherapie:</h4>
                          <p>
                            Das von uns empfohlene Standard-Buch in der 4.
                            Auflage:
                          </p>
                          <p>
                            <strong>Ozon in der Medizin.</strong> Das
                            Niedrig-Dosis Ozonkonzept als Bioregulator
                            zellul&auml;rer Antioxidantien und als
                            Immunmodulator
                          </p>
                          <p>
                            Wissenschaft &ndash; Guidelines -
                            Behandlungskonzepte
                          </p>
                          <p>
                            4. Auflage; MedO3 Publisher 2022; ISBN
                            978-3-949499-12-8
                          </p>
                          <p>
                            <strong>ABSTRACT</strong>
                          </p>
                          <p>
                            Die Ozon-Sauerstoff-Therapie in Form des
                            Niedrig-Dosis-Konzeptes hat sich als fundiertes
                            komplement&auml;rmedizinisches Verfahren
                            etabliert.&nbsp;
                          </p>
                          <p>
                            Als wichtigste systemische Applikationsformen
                            erweisen sich die &laquo;Gro&szlig;e
                            Ozon-Eigenblutinfusion&raquo; und die &laquo;Rektale
                            Verabreichung&raquo; gem&auml;&szlig; der
                            internationalen Klassifizierung als evidenzbasiert.
                          </p>
                          <p>
                            Wirkmechanismen und Pharmakologie sind gut
                            erforscht, dokumentiert und international
                            &laquo;peer reviewed&raquo; publiziert, womit die
                            Indikationen ihr wissenschaftliches Fundament
                            erhalten: Chronisch entz&uuml;ndliche Erkrankungen
                            und solche, die mit einer chronischen
                            Entz&uuml;ndung einhergehen:
                          </p>
                          <p>
                            &bull; Angiopathien und arterielle
                            Durchblutungsst&ouml;rungen
                          </p>
                          <p>&bull; Rheumatoide Arthritis, Schmerztherapie</p>
                          <p>&bull; Hepatitiden</p>
                          <p>&bull; Komplement&auml;re Onkologie</p>
                          <p>
                            Die Grundlagen und Behandlungskonzepte in der
                            aktuellen, online frei zug&auml;nglichen Publikation
                            (englisch):
                          </p>
                          <p>
                            <em>
                              &laquo;Ozone in Medicine. The Low-Dose Ozone
                              Concept and Its Basic Biochemical Mechanisms of
                              Action in Chronic Inflammatory Diseases&raquo;:
                            </em>
                          </p>
                          <p>
                            <strong>ABSTRACT</strong>
                          </p>
                          <p>
                            Auf der Grundlage der Guidelines der Ozontherapie
                            werden Behandlungskonzepte vorgestellt:
                            Indikationen, Applikationen, Dosierungen,
                            Ozonkonzentrationen,
                          </p>
                          <p>
                            Behandlungsfrequenzen; ebenso die Grundlagen in
                            gestraffter Form sowie Pharmakodynamik und
                            Pharmakokinetik des Ozons
                          </p>
                          <p>
                            Renate Viebahn-Haensler und Olga Sonia Le&oacute;n
                            Fern&aacute;ndez haben die Wirkung und die
                            Mechanismen von Ozon in niedrigen Dosen bei
                            chronischen Entz&uuml;ndungen dargestellt und
                            diskutiert: Ozon wirkt hier als Bioregulator bei
                            Krankheiten, die biochemisch durch hohen oxidativen
                            Stress gekennzeichnet sind.{" "}
                            <strong>Ozon in niedrigen Dosen</strong> induziert
                            moderaten oxidativen Stress und{" "}
                            <strong>
                              stellt eine wirksame &laquo;hormetische&raquo;
                              Strategie dar
                            </strong>
                            .&nbsp;
                          </p>
                          <p>
                            (<em>Hormesis - griech.:</em> &bdquo;
                            <em>Anregung, Ansto&szlig;</em>&ldquo;,{" "}
                            <em>engl</em>.: &bdquo;<em>adaptive response</em>
                            &ldquo; -{" "}
                            <em>
                              bezeichnet das Ph&auml;nomen, dass geringe Dosen
                              sch&auml;dlicher oder giftiger Substanzen oder
                              stressausl&ouml;sender Umweltfaktoren aufgrund
                              regulativer Signale auf betroffene Organismen
                              letztendlich eine g&uuml;nstige Auswirkung haben).
                            </em>
                          </p>
                          <p>
                            Es wurden internationale Leitlinien f&uuml;r die
                            Auswahl der geeigneten Materialien und der wirksamen
                            Konzentrations- und Dosierungsbereiche aufgestellt,
                            um den besten therapeutischen Nutzen zu erzielen.
                            Bei der Behandlung chronischer
                            Entz&uuml;ndungskrankheiten sollten Kliniker die
                            Verabreichung von medizinischem Ozon mit
                            konventionellen Therapeutika und Arzneimitteln
                            kombinieren, um dank der bioregulatorischen
                            Mechanismen des Ozons eine synergistische Wirkung zu
                            erzielen und die Nebenwirkungen von Arzneimitteln,
                            insbesondere auf Leber und Nieren, zu verringern.
                          </p>
                          <p>
                            Viebahn-Haensler, R.; Le&oacute;n Fern&aacute;ndez,
                            O.S. Ozone in Medicine. The Low-Dose Ozone Concept
                            and Its Basic Biochemical Mechanisms of Action in
                            Chronic Inflammatory Diseases. Int. J. Mol. Sci.{" "}
                            <strong>2021</strong>, 22, 7890.
                          </p>
                          <p>
                            &nbsp;
                            <a href="https://pubmed.ncbi.nlm.nih.gov/34360655/">
                              https://pubmed.ncbi.nlm.nih.gov/34360655/
                            </a>
                          </p>

                          <p>
                            Zwei herausragende, zur Lekt&uuml;re empfohlene
                            Publikationen aus dem Jahr 2024:
                          </p>

                          <p>
                            Mitochondrial Dysfunction: Its Oxidative
                            Stress-Induced Pathologies and Redox Bioregulation
                            through Low-Dose Medical Ozone. A Systematic
                            Review&nbsp;
                          </p>
                          <p>
                            by&nbsp;Renate Viebahn-Haensler and Olga Sonia
                            Le&oacute;n Fern&aacute;ndez
                          </p>
                          <p>
                            (Medical Society for the Use of Ozone in Prevention
                            and Therapy, D-76473 Iffezheim, Germany
                          </p>
                          <p>
                            bzw. Pharmacy and Food Institute, University of
                            Havana, Cuba)
                          </p>
                          <p>
                            In: Molecules 2024, 29(12), 2738, Published: 8 June
                            2024
                          </p>
                          <p>
                            <a href="https://doi.org/10.3390/molecules29122738">
                              <strong>
                                https://doi.org/10.3390/molecules29122738
                              </strong>
                            </a>
                          </p>

                          <p>
                            <strong>ABSTRACT</strong>
                          </p>
                          <p>
                            <strong>
                              Ozon in niedriger Dosierung wirkt als
                              Redox-Bioregulator
                            </strong>
                            .
                          </p>
                          <p>
                            Die Hypothese, dass kontrollierte Ozonanwendungen
                            das Redox-Gleichgewicht eines biologischen
                            Organismus beeinflussen (erstmals 1998 mit einer
                            pr&auml;klinischen Studie zum Schutz der Leber vor
                            CCl4-Intoxikation ver&ouml;ffentlicht), hat sich in
                            den letzten zwei Jahrzehnten bei durch reaktive
                            Sauerstoffspezies (ROS) verursachten mitochondrialen
                            Pathologien wie rheumatoide Arthritis,
                            Osteoarthritis, Alterungsprozesse und Typ-2-Diabetes
                            sowie bei der Pr&auml;vention von Intoxikationen
                            best&auml;tigt.&nbsp;
                          </p>
                          <p>
                            Die Wiederherstellung des gest&ouml;rten
                            Redox-Gleichgewichts ist in einer Reihe von
                            pr&auml;klinischen und klinischen Studien durch
                            einen bemerkenswerten Anstieg der antioxidativen
                            Reparaturmarker nachvollziehbar, hier vor allem
                            durch einen Anstieg des Glutathions und eine
                            Verringerung der Marker f&uuml;r oxidativen Stress,
                            haupts&auml;chlich Malondialdehyd.&nbsp;
                          </p>
                          <p>
                            Der Wirkmechanismus wird aufgezeigt und relevante
                            Daten werden dargestellt, ausgewertet und umfassend
                            diskutiert. Die erw&uuml;nschte Seite des
                            Gleichgewichts steigt dabei im Vergleich zu den
                            nicht-ozonbehandelten Gruppen um 21% bis 140%. Je
                            nach Indikation werden gleichzeitig die Stressmarker
                            reduziert. Das Redoxsystem kommt wieder ins
                            Gleichgewicht.
                          </p>
                          <p>
                            Stichworte: Redox-Bioregulation;
                            Mitochondriopathien; Ozontherapie; oxidativer
                            Stress; antioxidative Kapazit&auml;t
                          </p>

                          <p>Und:</p>

                          <p>
                            Medical Ozone: Redox Regulation and a New Mechanism
                            in the Treatment of Rheumatoid Arthritis
                          </p>

                          <p>
                            Olga Sonia Le&oacute;n Fern&aacute;ndez, Gabriel
                            Takon Oru, Renate Viebahn-Haensler, Gilberto
                            L&oacute;pez Cabreja, Irainis Serrano Espinosa,
                            Mar&iacute;a Elena Corrales V&aacute;zquez.
                          </p>
                          <p>Posted Date: 4 October 2024</p>
                          <p>
                            Keywords: Ozone; Reactive Oxygen Species; Rheumatoid
                            Arthritis; Methotrexate; A1 Adenosine Receptors
                          </p>

                          <p>
                            <a href="https://www.preprints.org/manuscript/202410.0288/v1">
                              https://www.preprints.org/manuscript/202410.0288/v1
                            </a>
                          </p>

                          <p>
                            <strong>ABSTRACT</strong>
                          </p>
                          <p>
                            Medizinisches Ozon ist ein Redox-Regulator mit
                            positiven Wirkungen bei oxidativ bedingten
                            Krankheiten wie rheumatoider Arthritis (RA). Ziel
                            dieser Studie war es, einen ganzheitlichen
                            &Uuml;berblick &uuml;ber verschiedene
                            pharmakologische Studien mit Ozon bei
                            Modellkrankheiten sowie &uuml;ber die klinische
                            Reaktion von RA-Patienten zu geben. Dabei wurden die
                            an der RA beteiligten ROS und ihre Beziehung zu den
                            wichtigsten Signalwegen dieser Autoimmunerkrankung
                            ber&uuml;cksichtigt. Die integrative Analyse von
                            tierexperimentellen Ergebnissen und klinischen
                            Befunden hat gezeigt, dass Methotrexat (MTX) und
                            medizinisches Ozon &uuml;ber die
                            <strong>&nbsp;</strong>&laquo;adenosinerge
                            Regulierung&raquo;<strong>&nbsp;</strong>
                            (Adenosin-A1-Rezeptoren)<strong>&nbsp;</strong>
                            gemeinsame Mechanismen<strong>&nbsp;</strong>
                            aufweisen.&nbsp;
                          </p>
                          <p>
                            Diese Erkenntnis hat es uns erm&ouml;glicht, einen
                            neuen pharmakologischen Mechanismus f&uuml;r die
                            Behandlung von RA vorzuschlagen.{" "}
                            <strong>
                              Wir kamen zum Schluss, dass die
                              Kombinationstherapie aus Methotrexat&nbsp;
                            </strong>
                            <strong>und</strong>
                            <strong>
                              &nbsp;medizinischem Ozon durch einen gemeinsamen
                              Mechanismus (an dem Adenosin-A1-Rezeptoren
                              beteiligt sind) die &Uuml;berproduktion von ROS
                              und die Bildung proinflammatorischer Zytokine
                              reduziert sowie den Gehalt an Citrullinierten
                              Peptiden verringert.
                            </strong>
                          </p>
                          <p>
                            (Citrullinierte Proteine bzw. Peptide k&ouml;nnen
                            vom Immunsystem als k&ouml;rperfremd angesehen und
                            durch Antik&ouml;rper angegriffen werden. Sie
                            spielen bei der Pathogenese der rheumatoiden
                            Arthritis eine wichtige Rolle.)
                          </p>

                          <p>
                            F&uuml;r Hinweise auf weiterf&uuml;hrende Literatur
                            nachstehend zwei wichtige Quellen:
                            <strong>&nbsp;</strong>
                          </p>
                          <p>
                            (&Auml;rztliche Gesellschaft f&uuml;r Ozonanwendung
                            in Pr&auml;vention und Therapie, D-76473
                            Iffezheim/Baden-Baden)
                          </p>
                          <p>
                            <strong>
                              &nbsp;Literatur Liste &uuml;ber Medizinisches Ozon
                              mit den neusten Publikationen sowie einer
                              Auflistung wichtiger Publikationen&nbsp;
                            </strong>
                            (alphabetisch gegliedert nach Autoren-Namen):
                            <strong>&nbsp;</strong>
                          </p>
                          <p>
                            <a href="https://www.ozongesellschaft.de/pdf/literaturliste2024.pdf">
                              https://www.ozongesellschaft.de/pdf/literaturliste2024.pdf
                            </a>
                          </p>

                          <p>
                            <strong>
                              &nbsp;Weitere Literatur Liste zu wichtigen frei
                              zug&auml;nglichen Publikationen:
                            </strong>
                          </p>
                          <p>
                            <a href="https://www.ozongesellschaft.de/pdf/literature-list-journal.pdf">
                              https://www.ozongesellschaft.de/pdf/literature-list-journal.pdf
                            </a>
                          </p>
                        </p>
                        <p>
                          <span>
                            Umfangreiche Sammlung von mehr als 2‘100
                            Publikationen über Ozontherapie (auf Zotero):
                          </span>{" "}
                          <br></br>
                          <a
                            href="https://www.zotero.org/groups/isco3_ozone/items/collectionKey/7IRDCPQT"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          One of the ISCO3 objectives for 2011 was to develop
                          and maintain an updated online digital Ozonetherapy
                          library of all worldwide published papers on ozone
                          therapy. The work of setting such online library was
                          assigned by ISC03 to its member Professor Gregorio
                          Martinez Sanchez (Ph.D. Pharm. Dr), who is currently
                          the only person dealing directly with updating the
                          library. Memberships will be accepted for persons who
                          are well known researchers in the field of ozone
                          therapy. lf you want to join this group, send
                          invitation request to group administrator.<br></br>
                          <a
                            href="http://www.o3center.org/Articles/index.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          Scientific studies on the ozone and hydrogen peroxide
                          therapies: Most complete reference list Compiled by
                          Miika Sallinen:<br></br>
                          <a
                            href="http://otsoniterapia.net/tutkimusviitteita-en/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#links">
                      <h6>Links</h6>
                      <div>
                        <p>
                          <span>World Federation Of Ozone Therapy:</span>
                          <br></br>
                          <a
                            href="http://www.wfoot.org"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>{" "}
                          <br></br>
                          <FontAwesomeIcon
                            className="pdfIcon"
                            icon={faFilePdf}
                          ></FontAwesomeIcon>
                          <a
                            href="http://www.wfoot.org/wp-content/uploads/2014/10/WFOT-Constitution-and-bylaws-final-V5-formated.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>{" "}
                          <br></br>
                          <br></br>
                          <span>
                            Internat. Konsensus-Papier der World Federation of
                            Ozone Therapy - WFOT (2015):
                          </span>
                          <br></br>
                          <FontAwesomeIcon
                            className="pdfIcon"
                            icon={faFilePdf}
                          ></FontAwesomeIcon>
                          <a
                            href="http://www.wfoot.org/wp-content/uploads/2016/01/WFOT-OZONE-2015-ENG.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>„Madrid Deklaration“</span>
                          <br></br>
                          The 3rd edition of the “Madrid Declaration on Ozone
                          Therapy” is now available ISCO3, 3rd ed., 2020, 103
                          pages "For the Unification of Criteria in the Practice
                          of Ozone Therapy" Edition in English and only in
                          electronic format. Price 30€.
                          <br></br>
                          <a
                            href="https://isco3.org/producto/madrid-declaration-on-ozone-therapy-3rd-edition-online-access-english/?inf_contact_key=60849239e1d0419f2965d31994e30645680f8914173f9191b1c0223e68310bb1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>
                            The International Scientific Committee of Ozone
                            Therapy{" "}
                          </span>
                          <br></br>
                          <a
                            href="https://isco3.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>
                            Sammlung von mehr als 2100 Publikationen über
                            Ozontherapie (auf Zotero):
                          </span>
                          <br></br>
                          <a
                            href="https://www.zotero.org/groups/46074/isco3_ozone/collections/7IRDCPQT"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>International Journal of Ozone Therapy</span>
                          <br></br>
                          <a
                            href="https://www.wfoot.org/journals/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Journal of Ozone Therapy</span>
                          <br></br>
                          <a
                            href="https://ojs.uv.es/index.php/JO3T"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Deutschland:</span>
                          <br></br>
                          Unsere deutsche Schwestergesellschaft:
                          <br></br>
                          <a
                            href="http://www.ozongesellschaft.de/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          Gute Infos und verlässliche Bezugsquellen zur
                          Ozontherapie:
                          <br></br>
                          <a
                            href="http://www.ozonosan.de/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Italien:</span>
                          <br></br>
                          Webseiten mit teilweise ausführlichen Bibliographien:
                          <br></br>
                          <a
                            href="http://www.ossigenoozono.it/It/Home"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <a
                            href="https://www.nuovafio.it/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <a
                            href="http://www.ozonoterapia.com/biblio.htm"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <a
                            href="http://www.accademiaozono.com/it/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Spanien:</span>
                          <br></br>
                          SOCIEDAD ESPAÑOLA d‘OZONOTERAPIA:
                          <br></br>
                          <a
                            href="https://www.seot.es/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          ASOCIACIÓN ESPAÑOLA DE PROFESIONALES MÉDICOS EN
                          OZONOTERAPIA (AEPROMO):
                          <br></br>
                          <a
                            href="http://www.aepromo.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          ASOCIACION CIENTIFICA ESPAÑOLA DE APLICACIÓN DE
                          OXIGENO-OZONO TERAPIA:
                          <br></br>
                          <a
                            href="https://www.seot.es/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          The Spanish Journal of Ozone Therapy:
                          <br></br>
                          <a
                            href="http://www.xn--revistaespaoladeozonoterapia-7xc.es/index.php/reo/index"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          Institut Català d‘OZONOTERAPIA:
                          <br></br>
                          <a
                            href="https://www.ozono-terapia.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Kuba:</span>
                          <br></br>
                          Webseite von kubanischen Forschern zu Ozontherapie/ Ox
                          Stress:
                          <br></br>
                          <a
                            href="http://www.ozono.cubaweb.cu/investigaciones/stress.htm"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Argentinien:</span>
                          <br></br>
                          Asociación Argentina del Ozono:
                          <br></br>
                          <a
                            href="http://www.adelo.com.ar/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Mexico:</span>
                          <br></br>
                          Associación Mexicana de Ozonoterapia, C.A.<br></br>
                          <a
                            href="http://o3.amozon.org.mx/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Brasilien:</span>
                          <br></br>
                          Associação Brasileira de Ozonioterapia (ABOZ){" "}
                          <br></br>
                          <a
                            href="http://www.aboz.org.br/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Asien:</span>
                          <br></br>
                          Asiatic-European Association of Ozone Therapists{" "}
                          <br></br>
                          <a
                            href="http://ozonetherapy.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          Virtual Free Radical School:
                          <br></br>
                          <a
                            href="http://www.medicine.uiowa.edu/FRRB/VirtualSchool/Virtual.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>Sonstiges:</span>
                          <br></br>
                          Freie Radikale und Oxidativer Stress <br></br>
                          <a
                            href="http://www.inform24.de/radikale.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          Dissertation 1998 aus der Humboldt-Universität Berlin:
                          <br></br>
                          <a
                            href="https://edoc.hu-berlin.de/handle/18452/15051#x1.2.3"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          Neurofibrillary Degeneration (NFD):
                          <br></br>
                          <a
                            href="http://www.alzheimer-adna.com/Abeta/cascadehypothesisGB.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          The Nrf2-ARE Pathway: An Indicator and Modulator of
                          Oxidative Stress in Neurodegeneration
                          <br></br>
                          <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2605641/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#literatur">
                      <h6>Literatur</h6>
                      <div>
                        <p>
                          Das von uns empfohlene Standard-Buch in der 4.
                          Auflage:
                        </p>
                        <p>
                          <strong>Ozon in der Medizin.</strong> Das
                          Niedrig-Dosis Ozonkonzept als Bioregulator
                          zellul&auml;rer Antioxidantien und als Immunmodulator
                        </p>
                        <p>
                          Wissenschaft &ndash; Guidelines - Behandlungskonzepte
                        </p>
                        <p>
                          4. Auflage; MedO3 Publisher 2022; ISBN
                          978-3-949499-12-8
                        </p>
                        <p>
                          <br></br>
                          <span>Ozone Therapy</span> <br></br>
                          Oxidative Conditioning, Basis for its Clinical
                          Effectiveness Prof. Olga Sonia Leon Fernandez PHD
                          Englisch, 202 Seiten, ODREI Publishers 2014 ISBN
                          978-3-934181-03-7 <br></br>
                          <br></br>
                          The book gives information about the mechanisms of
                          action, which justify the therapeutical effectiveness
                          of medical ozone. It explains why that specific
                          therapeutical concept is effective in so many
                          illnesses. „The ozonetherapy is a therapeutical
                          alternative that has demonstrated its clinical
                          effectiveness in the treatment of a wide variety of
                          physiopathological states apparently not related to
                          each other. Examples of them are the vascular
                          dysfunctions, the infectious process, rheumatoid
                          arthritis, disc hernia, diabetes, convulsions,
                          ischemic syndrome and others.<br></br>
                          <br></br> LINEE GUIDA E BUONE PRATICHE IN
                          OSSIGENO-OZONO TERAPIA<br></br>
                          <br></br>
                          Kostenloser Download (ausschliesslich in italienischer
                          Sprache erhältlich) zu bestellen über:
                          <br></br>
                          <a
                            href="https://www.lineeguidaozono.it"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Bezugsmöglichkeit
                          </a>
                          <br></br>
                          <br></br>
                          <span>Clinical Ozone Therapy Manual</span> <br></br>
                          (DIGITAL VERSION) Dr. Schwartz Adriana. Medizeus,
                          Medical Solutions SL, (2020) 626 p. + XXVI. ISBN:
                          978-84-09-22453-1. <br></br>
                          <br></br>
                          Foreword by Dr. Juan del Rey Calero Emeritus Professor
                          of Preventive Medicine, Autonomous University of
                          Madrid. Full Member, Spanish Royal National Academy of
                          Medicine. Madrid (Spain). <br></br> <br></br>{" "}
                          <b>Price:150,00€</b> <br></br> <br></br> LANGUAGE:
                          ENGLISH. PRINTED VERSION NOT AVAILABLE FOR NOW.
                          <br></br>
                          <br></br>
                          <a
                            href="https://formacionmedizeus.com/wp-content/uploads/2020/09/Indice-completo-libro-inglés.pdf?utm_source=BenchmarkEmail&utm_campaign=LIBRO_INGLÉS_-_17%2f03%2f2022&utm_medium=email"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link zum Inhaltsverzeichnis
                          </a>
                          <br></br>
                          <a
                            href="https://formacionmedizeus.com/en/product/clinical-ozone-therapy-manual-digital-version/?utm_source=BenchmarkEmail&utm_campaign=LIBRO_INGLÉS_-_17%2f03%2f2022&utm_medium=email"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Bezugsmöglichkeit
                          </a>
                          <br></br>
                          <br></br>
                          <span>Guide to Using Medical Ozone</span> <br></br>
                          Therapeutic Foundations and Indications the most
                          complete work written in Spanish. Adriana Schwartz et
                          al., AEPROMO, 2011, 315 p. + XVIII + 11 p. Color
                          plates. ISBN: 978-84-615-2244-6
                          <br></br>
                          <br></br>
                          The book gives information about the properties of the
                          ozone, its mechanisms of action, forms and methods of
                          application. In its pages you will find instructions
                          to properly implement this valuable therapy as
                          recommended by the authors, experts in research and
                          clinical practice of ozone.
                          <br></br>
                          <br></br>
                          <span>
                            1. Aktuelle Publikation von Dezember 2011:
                            Mechanisms of Action Involved in Ozone Therapy: „Is
                            healing induced via a mild oxidative stress?“
                          </span>{" "}
                          <br></br>
                          <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3298518/?tool=pubmed"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>
                            2. Scientific and Medical Aspects of Ozone Therapy
                          </span>{" "}
                          <br></br>
                          Bocci, 2006 Scientific and medical aspects of ozone
                          therapy. State of the Art 11 Seiten, davon 2 Seiten
                          mit 64 Referenzen; in englischer Sprache. Eine sehr
                          lesenswerte Standortbestimmung mit folgenden Kapiteln:
                          <br></br>Einleitung; Was ist Ozon und wozu verwenden
                          wir es?; Was geschieht mit Ozon bei Kontakt mit
                          Körperflüssigkeiten?; Was sind die biologischen
                          Wirkungen von ROS und LOP?; Anwendungsweise von Ozon;
                          Wann ist Ozontherapie indiziert?; Hyperbare
                          Sauerstofftherapie und Ozontherapie; Zusammenfassung
                          und Ausblick.
                          <br></br>
                          <a
                            href="https://pubmed.ncbi.nlm.nih.gov/16624639/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>
                            3. Oxygen-Ozone Therapy in Medicine: An Update
                          </span>{" "}
                          <br></br>
                          Blood Purif. 2009 Sep 10;28(4):373-376. <br></br>{" "}
                          [Epub ahead of print] Bocci V, Di Paolo N. Department
                          of Physiology, University of Siena, Siena, Italy
                          <br></br>
                          <br></br>
                          Oxygen-ozone therapy, initially started as an
                          empirical approach, has now reached a stage where most
                          of the biological mechanisms of action of ozone have
                          been clarified, showing that they are in the realm of
                          orthodox biochemistry, physiology and pharmacology.
                          <br></br>
                          <br></br>
                          <span>
                            4. Meilensteine des Medizinischen Ozons
                          </span>{" "}
                          <br></br>
                          Medizinisches Ozon im Blickpunkt der Kritik. 15.
                          Ozon-Weltkongress 2001 in London, Eröffnungsvortrag
                          (deutsche Version)
                          <br></br>
                          <FontAwesomeIcon
                            className="pdfIcon"
                            icon={faFilePdf}
                          ></FontAwesomeIcon>
                          <a
                            href="http://www.ozonosan.de/images/upload/File/Meilensteine_des_Medizinischen_Ozons__London_2001.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>
                            5. The Current Satus of Ozone Therapy
                          </span>{" "}
                          <br></br>
                          Empiric Developments and Basic Research
                          Forsch.Komplementärmed. 1998; 30 Seiten, 72
                          Referenzen. PDF-Download unter:
                          <br></br>
                          <FontAwesomeIcon
                            className="pdfIcon"
                            icon={faFilePdf}
                          ></FontAwesomeIcon>
                          <a
                            href="http://www.ozonosan.de/images/upload/File/Ozone_Therapy_Current_Status_Research_in_Complementary_Medicine_1998.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Link zur Referenz
                          </a>
                          <br></br>
                          <br></br>
                          <span>6. OZONE A NEW MEDICAL DRUG</span> <br></br>
                          von Velio Bocci Prof. Velio Bocci ist Physiologe in
                          Siena, Italien. Er hat viele Forschungsarbeiten über
                          Ozontherapie durchgeführt und veröffentlicht. Springer
                          Verlag 2005; ISBN 1-4020-3139-4 (HB); ISBN
                          1-4020-3140-8 (e-book); EUR 159.00 <br></br>
                          <br></br> Zitat: „Ozone can be considered a
                          pleiotropic*) bioregulator because it generates a
                          reaction cascade of several compounds responsible for
                          a variety of biologigcal effects.“ *) pleios=viel;
                          trop= Wirkung, Richtung; <br></br>
                          <br></br> Pleiotropy describes the genetic effect of a
                          single gene on multiple phenotypic traits. The
                          underlying mechanism is that the gene codes for a
                          product that is for example used by various cells, or
                          has a signalling function on various targets <br></br>
                          <br></br> This book clarifies that, in the often
                          nebulous field of natural medicine, the biological
                          bases of ozone therapy are totally in line with
                          classic biochemical, physiological and pharmacological
                          knowledge. <br></br>
                          <br></br> Ozone is an oxidising molecule, a sort of
                          superactive oxygen, which, by reacting with blood
                          components, generates a number of chemical messengers
                          responsible for activating crucial biological
                          functions such as oxygen delivery, immune activation,
                          release of hormones and induction of antioxidant
                          enzymes, which is an exceptional property for
                          correcting the chronic oxidative stress present in
                          atherosclerosis, diabetes, infections and cancer.
                          Moreover ozone therapy, by inducing nitric oxide
                          synthase, may mobilize endogenous stem cells, which
                          will promote regeneration of ischaemic tissues.{" "}
                          <br></br>
                          <br></br> The description of these phenomena offers
                          the first comprehensive picture for understanding how
                          ozone works and why, when properly used as a real drug
                          within the therapeutic range, not only does not
                          procure adverse effects but yields a feeling of
                          wellness. <br></br>
                          <br></br> Half of the book describes the value of
                          ozone therapy in several diseases, particularly
                          cutaneous infections and vascular diseases where ozone
                          really behaves as a "wonder" drug. The book has been
                          written for clinical researchers, physicians and
                          ozonetherapists but also for the layman or the patient
                          interested in this therapy.
                          <br></br>
                          <br></br>
                          <span>
                            7. Tropospheric Ozone Toxicity vs. Usefulness of
                            Ozone Therapy
                          </span>{" "}
                          <br></br>
                          von Velio Bocci Bocci VA Arch Med Res Feb 2007; 38(2)
                          Bezug Department of Physiology, University of Siena,
                          Siena, Italy. bocci@unisi.it <br></br>
                          <br></br> Zusammenfassung: <br></br>There is a general
                          consensus that continuous inhalation of air polluted
                          with ozone is detrimental for the lungs and vital
                          organs. Even if the concentration of tropospheric
                          ozone is slightly above the tolerated dose, toxicity
                          ensues owing to the cumulative dose inhaled for
                          months. <br></br>However, in medicine ozone is used as
                          a real drug and a precise concentration and
                          therapeutic dosage must be calibrated against the
                          antioxidant capacity of blood. As ozone reacts with
                          blood, it generates pharmacological messengers such as
                          H2O2 and lipid oxidation products (LOPs). These
                          activate several biochemical pathways in blood cells,
                          which after reinfusion are responsible for therapeutic
                          activities lasting several days. Neither acute nor
                          chronic toxicity has been registered.
                        </p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === "deutsch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("deutsch"));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === "französisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("französisch"));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === "italienisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("italienisch"));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Grundsätzliche Informationen
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + "images/background/arrowDown.svg"}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div className="row justify-content-center align-self-center sagosRow5">
        <div className="textBorderRight" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-8">
          <img
            className="sagosImage6-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + "images/background/sagos6.1.svg"}
            alt="doctors"
          ></img>
          <img
            className="sagosImage6-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + "images/background/sagos6.2.svg"}
            alt="doctors"
          ></img>
        </div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            <span>
              Bibliographie, <br></br> Links und Literatur
            </span>{" "}
            <br></br> zu der Ozontherapie
          </h1>
          <p>
            In dieser Sektion finden Sie wichtige literarische Werke, die die
            Ozontherapie in Hinblick ihrer Effektivität und
            Anwendungsmöglichkeiten unterstützt. Ebenfalls können Sie auf
            wichtige und interessante Publikationen von der weltweiten
            Ozontherapie Gesellschaft zugreifen!
          </p>
          <div className="buttonCenter">
            <button
              onClick={() => {
                handleShow();
                setModal1(true);
                fullpage.setAutoScrolling(false);
              }}
            >
              Mehr zum Thema
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
